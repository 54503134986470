import React, { useEffect, useState } from "react";
import {
  Button,
  Grid,
  Stack,
  Typography,
  TableCell,
  TableRow,
  TableBody,
  Table,
  Card,
  TableContainer,
  Tabs,
  Autocomplete,
  TextField,
  Pagination,
  PaginationItem,
  useTheme,
  CardContent,
  Divider,
  TableSortLabel 
} from "@mui/material";
import "./SiteList.css";
import Tab from "@mui/material/Tab";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { CheckForLight, shouldHighlightRed } from "../util/common/CommanFunction";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import WifiOffOutlinedIcon from "@mui/icons-material/WifiOffOutlined";
import { FadeLoader } from "react-spinners";
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import {
  fetchSiteEquipment,
  fetchSiteAlerms,
  fetchSiteAlermsfilter,
} from "../../Api/SiteListApi";
import {
  EnergymeterTables,
  ScbParams,
  ScbTables,
  SensorTables,
  fetchEquipmentlistTable,
} from "../../Api/SiteListApi";
import Scbaccorian from "../SiteConfiguration/Scbaccoridon "
export default function Equipmenthome(props) {
  const { SiteId, irradiation } = props;
 

  const theme = useTheme();
  const [siteValue, setSiteValue] = useState([]);
  const [tablevalue, setTableValue] = useState("Equipment");
  const [page, setPage] = useState(0);
  const [siteAlarms, setSiteAlerms] = useState([]);
  const [equimentalarms, setEquimentAlarms] = useState([]);
  const [lodervalue, setLodervalue] = useState(true);
  const [activeSection, setActiveSection] = useState("Equipment");
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [pageValue, setPageValue] = useState(0);
  const [valuedata, setValueData] = useState([]);
  const [equipment, setEquipment] = useState(true);
  const [apiResults, setApiResults] = useState({});
 
  
  const colorMode = theme.palette.mode;
  useEffect(() => {
    fetchDatadataTable();
    fetchDataEquipment();
  }, []);
  const fetchDataTable = async () => {
    setLodervalue(true);
    try {
      const data = await fetchSiteAlerms(SiteId);
      if (Array.isArray(data)) {
        setSiteAlerms(data);
      } else if (typeof data === "object" && data !== null) {
        setSiteAlerms([data]);
      }
    } catch (e) {
      console.error(e);
    }
    setLodervalue(false);
  };
  function isIdPresent(object, id) {
    return object.hasOwnProperty(id);
  }

  function extractAndSortString(data) {
    // Filter input entries
    const inputEntries = Object.entries(data)
      .filter(([key, value]) => key.startsWith('inputCurrent'))
      .map(([key, value]) => ({ key, value }));

    // Filter voltage entries
    const voltageEntries = Object.entries(data)
      .filter(([key, value]) => key.startsWith('inputVoltage'))
      .map(([key, value]) => ({ key, value }));

    // Sort input entries
    inputEntries.sort((a, b) => {
      const aIndex = parseInt(a.key.split('_')[1]);
      const bIndex = parseInt(b.key.split('_')[1]);
      return aIndex - bIndex;
    });

    let usedVoltageValue = null;

    // If only one voltage value is present and the rest are null, use that for all power calculations
    const nonNullVoltageEntries = voltageEntries.filter(
      ({ value }) => value !== null
    );
    if (nonNullVoltageEntries.length === 1) {
      usedVoltageValue = nonNullVoltageEntries[0].value;
    }

    // Create the sorted string entries
    const sortedString = inputEntries.map(({ key, value }, index) => {
      const inputKey = key;
      const voltageKey =
        usedVoltageValue !== null
          ? voltageEntries[0].key
          : voltageEntries[index].key;
      const inputValue = value;
      const voltageValue =
        usedVoltageValue !== null && inputValue !== 0
          ? usedVoltageValue
          : voltageEntries[index].value;
      const inputPower = `inputPower_${index + 1}`;
      const result = (inputValue * voltageValue).toFixed(2);
      console.log(inputValue, voltageValue, result, 'power');
      const stringKey = `String_${index < 9 ? '0' : ''}${index + 1}`;

      return {
        [stringKey]: {
          [inputKey]: inputValue == null ? '    -' : inputValue,
          [voltageKey]: voltageValue == null ? 0 : voltageValue,
          [inputPower]: result,
          down: inputValue === 0 || voltageValue === 0 ? true : false,
        },
      };
    });

    return sortedString;
  }

  function isAnyDown(obj, index) {
    const data = extractAndSortString(obj);
    // Loop through each object until the given index
    for (let i = 0; i <= index; i++) {
      // Get the keys of the object at index i
      let keys = Object.keys(data[i]);

      // Loop through each key in the object
      for (let key of keys) {
        // Check if the down value is true
        if (data[i][key].down === true) {
          return true;
        }
      }
    }
    return false;
  }
  const handleExpand = async (id) => {
    setExpanded({
      open: expanded.id === id ? !expanded.open : true,
      id: id,
    });
  };
  const [expanded, setExpanded] = useState({ open: false, id: '' });
  const fetchDatadataTable = async () => {
    setLodervalue(true);
    try {
    
      const data = await fetchSiteEquipment(SiteId);
      if (Array.isArray(data)) {
        setEquimentAlarms(data);
      } else if (typeof data === "object" && data !== null) {
        setEquimentAlarms([data]);
      }
      setLodervalue(false);
    } catch (e) {
      console.error(e);
    }
  };

  const fetchDataEquipment = async () => {
    setLodervalue(true);
    try {
      const  result = await fetchEquipmentlistTable(`${SiteId}/${irradiation}`);
      const data = result.map((item) => {
        return { ...item, activePower: item.activePower / 1000 };
      });
      if (Array.isArray(data)) {
        setValueData(data);
      } else if (typeof data === "object" && data !== null) {
        setValueData([data]);
      }
    } catch (e) {
      console.error(e);
    }
    setLodervalue(false);
  };

  
  let backgroundColor;
  if (CheckForLight()) {
    backgroundColor = activeSection === "Equipment" ? "" : "rgb(246, 248, 252)";
  } else {
    backgroundColor = activeSection === "Equipment" ? "" : "#4f4f4f";
  }

  let backgroundColorr;
  if (CheckForLight()) {
    backgroundColorr = activeSection === "Alarms" ? "" : "white";
  } else {
    backgroundColorr = activeSection === "Alarms" ? "" : "#4f4f4f";
  }
  const handleSectionClick = (value) => {
    setActiveSection(value);

    if (value === "Alarms") {
      fetchDataTable();
      setEquipment(false);
      fetchDatadataTable();
      setValue(0)
    }
    if (value === "Equipment") {
      setEquipment(true);
    }
  };
  const [value, setValue] = React.useState(0);
  const [categoryvalue, setCategoryValue] = useState("Inverter");
  console.log(categoryvalue);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (value === 1) {
      fetchDatadataTable();
    }
  };
  console.log(value);


  const Tablevalues = value === 0 ? equimentalarms : siteAlarms;
  const Tabledata = equipment === true ? valuedata : Tablevalues;
  console.log(Tabledata);
  
  // Assuming `categoryValue` is available in `Tabledata`


  

  const scaleActivePower = (data) =>
    data.map((item) => ({
      ...item,
      activePower: item.activePower ? item.activePower / 1000 : null,
    }));
  
  const extractNestedValues = (nestedObj, keyPrefix) =>
    Object.entries(nestedObj || {})
      .filter(([key]) => key.startsWith(keyPrefix))
      .map(([key, value]) => ({ [key]: value }));
  const processSCBStatus = (data) => {
    return data.map(item => {
      // If the item is an SCB (has scbStringValue and components)
      if (item.scbStringValue && item.components) {
        // Extract input current values based on the number of components
        const inputCurrents = Array.from(
          { length: item.components }, 
          (_, i) => item.scbStringValue[`inputCurrent_${(i + 1).toString().padStart(1, '0')}`]
        );
  
        // Check if any relevant input current is 0 or null
        const hasZeroValue = inputCurrents.some(current => 
          current === 0 || current === null
        );
  
        // Add status field
        return {
          ...item,
          derivedStatusName: hasZeroValue ? "Warning" : "Active"
        };
      }
      
      // For non-SCB items, return as is
      return item;
    });
  };
  console.log(processSCBStatus);
  
  // Modified handleEquipment function
  const handleEquipment = async (event, value) => {
    setLodervalue(true);
    setEquipment(true);
    setCategoryValue(event);
    setSiteAlerms([]);
    setEquimentAlarms([]);
    setExpandedRows({});

    let data = [];
    try {
        // Fetch data based on the category selected
        switch (event) {
            case "Inverter":
                data = await fetchEquipmentlistTable(`${SiteId}/${irradiation}`);
                break;
            case "Energy Meter":
                data = await EnergymeterTables(SiteId);
                break;
            case "SCB":
                data = await ScbTables(SiteId);
                break;
            case "Sensor":
                data = await SensorTables(SiteId);
                break;
            default:
                console.error("Invalid category value:", event);
                setLodervalue(false);
                return;
        }

        // Process SCB data if needed
        const processedData = data.map(item => {
            const { components, scbStringValue, ...rest } = item;

            // Safely filter SCB string values based on components count
            const filteredScbStringValue = components ? Object.fromEntries(
                Object.entries(scbStringValue).map(([key, value]) => {
                    if (/input(Current|Voltage)_\d+/.test(key)) {
                        const index = parseInt(key.split('_')[1]);
                        return index <= components ? [key, value] : null;
                    }
                    return [key, value];
                }).filter(Boolean) // Remove null entries
            ) : {};

            return {
                ...rest,
                components,
                scbStringValue: filteredScbStringValue,
                activePower: item.activePower ? item.activePower / 1000 : null,
            };
        });

        // Set the processed data to the state
        setValueData(processSCBStatus(processedData));
    } catch (error) {
        console.error("Error fetching equipment data:", error);
    } finally {
        setLodervalue(false);
    }
};


  const rowsPerPageOptions = [5, 10, 15, 20];

  const handleChangePage = (event, newPage) => {
    setPage(newPage - 1);
  };

  const handleChangeRowsPerPage = (event, newValue) => {
    setRowsPerPage(newValue);
    setPage(0);
  };

  const handleClickGo = () => {
    if (
      pageValue > 0 &&
      pageValue <= Math.ceil(Tabledata.length / rowsPerPage)
    ) {
      setPage(pageValue - 1);
    }
  };

  const PageChange = (value) => {
    const newPageValue = parseInt(value, 5);
    if (!isNaN(newPageValue)) {
      setPageValue(newPageValue);
    }
  };

  const paginatedData = Tabledata.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const renderCustomPrevious = (props) => (
    <CustomIconSlot
      name="PREVIOUS"
      icon={<ArrowBackIcon style={{ fontSize: "16px" }} />}
      {...props}
    />
  );
  const CustomIconSlot = ({ name, icon, ...props }) => (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: name === "NEXT" ? "row-reverse" : "row",
      }}
    >
      {React.cloneElement(icon, props)}
      <Typography
        style={{
          margin: "14px",
          color: colorMode === "light" ? "#484C46" : "#007AFF",
          fontSize: "12px",
        }}
      >
        {name}
      </Typography>
    </div>
  );
  const renderCustomNext = (props) => (
    <CustomIconSlot
      name="NEXT"
      icon={
        <ArrowForwardIcon
          // sx={{ color: colorMode === 'light' ? '#484C46' : '#007AFF' }}
          style={{ fontSize: "16px" }}
        />
      }
      {...props}
    />
  );
 
  const tableConfigs = {};

  if (value === 0) {
    if (equimentalarms.length === 0) {
      tableConfigs.table1 = {
        message: "Currently, there are no alarms configured to display.",
      };
    } else {
      tableConfigs.table1 = {
        headers: [
          "Equipment Name",
          "Error Code",
          "Error Message",
          "Event Time",
          "Occurrence",
          "Priority",
          "Capacity",
        ],
        keys: [
          "displayName",
          "errorCode",
          "errorMessage",
          "lastOccurence",
          "eventOccurence",
          "priority",
          "dcCapacity",
        ],
      };
    }
  // } else if (value === 1) {
  //   if (equimentalarms.length === 0) {
  //     tableConfigs.table1 = {
  //       message: "There are currently no alarms to display at this moment.",
  //     };
  //   } else {
  //     tableConfigs.table1 = {
  //       headers: ["Another Header 1", "Another Header 2", "Another Header 3"],
  //       keys: ["field1", "field2", "field3"],
  //     };
  //   }
  }
  


  
  switch (categoryvalue) {
    case "Inverter":
      if (valuedata.length === 0) {
        tableConfigs.table2 = {
          message: 'Currently, there are no equipment configured to display.',
        };
      } else {
        tableConfigs.table2 = {
          headers: [
            "Equipment Name",
            "Serial No",
            "Today Energy (kWh)",
            "Total Energy (kWh)",
            "Specific Yield",
            "PR (%)",
            "Active Power (kW)",
            "Last Updated",
            "Status",
          ],
          keys: [
            "displayName",
            "serialNo",
            "todayEnergy",
            "totalEnergy",
            "specificYield",
            "performanceRatio",
            "activePower",
            "energyLastUpdate",
            "derivedStatusName",
          ],
        };
      }
      break;
  
    case "Energy Meter":
      if (valuedata.length === 0) {
        tableConfigs.table2 = {
          message: 'At this moment, there are no energy meter devices to display.',
        };
      } else {
        tableConfigs.table2 = {
          headers: [
            "Equipment Name",
            "Serial No",
            "Active Power (kWh)",
            "Reactive Power (kVAr)",
            "Import Energy (kWh)",
            "Export Energy (kWh)",
            "Power Factor",
            "Last Updated",
          ],
          keys: [
            "displayName",
            "serialNo",
            "activePower",
            "reactivePower",
            "importEnergy",
            "exportEnergy",
            "powerFactor",
            "lastUpdated",
          ],
        };
      }
      break;
  
    case "SCB":
      if (valuedata.length === 0) {
        tableConfigs.table2 = {
          message: 'Currently, there are no SCB configured to display.',
        };
      } else {
        tableConfigs.table2 = {
          headers: [
            "Equipment Name",
            "Serial No",
            "Components",
            "Last Uploaded",
            "Status"
          ],
          keys: [
           
            "serialNo",
            "components",
            "lastUpdated",
            "derivedStatusName"
          ],
        };
      }
      break;
  
    case "Sensor":
      if (valuedata.length === 0) {
        tableConfigs.table2 = {
          message: 'At this time, there are no sensors configured to display.',
        };
      } else {
        tableConfigs.table2 = {
          headers: [
            "Equipment Name",
            "Serial No",
            "Ambient Temperature (°C)",
            "Module Temperature (°C)",
            "Irradiance (W/m2)",
            "Wind Speed (°)",
            "Wind Direction (m/s)",
            "Last Updated",
          ],
          keys: [
            "displayName",
            "serialNo",
            "ambientTemperature",
            "moduleTemperature",
            "irradiation",
            "windSpeed",
            "windDirection",
            "lastUpdated",
          ],
        };
      }
      break;
  
    default:
      tableConfigs.table2 = {
        message: 'No data available for the selected category.',
      };
      break;
  }
  function getStatusIcons(status) {
    switch (status) {
      case "Active":
        return (
          <CheckCircleOutlineOutlinedIcon
            style={{
              fontSize: "16px",
              marginRight: "1px",
            }}
          />
        );
      case "Offline":
        return (
          <WifiOffOutlinedIcon
            style={{
              fontSize: "16px",
              marginRight: "1px",
            }}
          />
        );
      case "Warning":
        return (
          <WarningAmberOutlinedIcon
            style={{
              fontSize: "16px",
              marginRight: "1px",
            }}
          />
        );
      case "Down":
      case "Inactive":
        return (
          <HighlightOffOutlinedIcon
            style={{
              fontSize: "16px",
              marginRight: "1px",
            }}
          />
        );
      default:
        break;
    }
  }
  function getStatusIconDark(status) {
    let backgroundColor, textColor, textcenter;
    
    // Default values to ensure variables are always defined
    backgroundColor = "rgba(0, 0, 0, 0.2)";
    textColor = "black";
    textcenter = "center"; // Assuming you want the text to be aligned to the center in all cases

    switch (status) {
        case "Active":
            backgroundColor = "rgba(11, 223, 42, 0.2)";
            textColor = "rgb(1, 50, 32)";
            break;
        case "Inactive":
        case "Offline":
            backgroundColor = "rgba(96, 61, 47, 0.2)";
            textColor = "black";
            break;
        case "Warning":
            backgroundColor = "rgba(255, 165, 0, 0.2)";
            textColor = "orange";
            break;
        case "Down":
            backgroundColor = "rgba(255, 0, 0, 0.2)";
            textColor = "red";
            break;
        default:
            // No change needed here because default values are already set
            break;
    }

    return { backgroundColor, textColor, textcenter };
}

 

  console.log(valuedata);
  

  
  function formatCellValue(key, value) {
    if (
      (key === "energyLastUpdate" || key === "lastOccurence" || key === "lastUpdated") &&
      value
  ) {
      const date = new Date(value);
    date.setHours(date.getHours() - 5);
    date.setMinutes(date.getMinutes() - 30);
      const formattedDate = date.toLocaleDateString("en-GB", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
      }).replace(/\//g, "-");
      const formattedTime = date.toLocaleTimeString("en-GB", {
          hour: "2-digit",
          minute: "2-digit",
      });
      return `${formattedDate} ${formattedTime}`;
  }


  
    if (key === "performanceRatio") {
  
      return (
          <div
              style={{
                  color: shouldHighlightRed("performanceRatio", value)
                      ? "red"
                      : "inherit",
              }}
          >
              {value===null?"-":value}
          </div>
      );
  }


    if (key === "totalEnergy"||key === "todayEnergy"||key === "performanceRatio"||key === "specificYield"||key === "reactivePower"||key === "importEnergy"||key ==="exportEnergy"||key ==="powerFactor"||key ==="ambientTemperature"||key ==="moduleTemperature"||key ==="irradiation"||key==="windSpeed"||key==="windDirection") {
        return value===null?"0.00":value.toFixed(2);
    }else if (key === "activePower"){
      return value===null?"0.000":value.toFixed(3)
    }

    if (key === "derivedStatusName") {
        const styles = getStatusIconDark(value);
        const icon = getStatusIcons(value);
        return (
            <div
                style={{
                    backgroundColor: styles.backgroundColor,
                    color: styles.textColor,
                    borderRadius: styles.borderRadius,
                  justifyContent: styles.textcenter,
                    display: "flex",
                
                    padding: "5px",
                    borderRadius:"20px"
                }}
            >
                {icon}
                <span style={{ marginLeft: "4px" }}>{value}</span>
            </div>
        );
    }
    if (key ==="specificYield"||key ==="lastUpdated"||key ==="activePower"){
      return value ===null?"-":value
    }

    return  value===null?"0":value
}


  const currentTable = equipment === true ? "table2" : "table1";
  const currentConfig = tableConfigs[currentTable];
  const badge = {
    borderRadius: "10px 10px 10px 10px",
    backgroundColor: "#1976d2",
    color: "white",
    fontSize: "11px",
    lineHeight: "18px",
    width: "30px",
    marginLeft: "2%",
  };
console.log(currentConfig);



  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });

  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };
  
  const sortedData = React.useMemo(() => {
    if (!sortConfig.key) return Tabledata;
  
    return [...Tabledata].sort((a, b) => {
      const aValue = a[sortConfig.key];
      const bValue = b[sortConfig.key];
  
      // Handle ascending sort based on starting value of 1
      if (aValue === 1 && bValue !== 1) {
        return -1; // a comes before b
      } 
      if (bValue === 1 && aValue !== 1) {
        return 1; // b comes before a
      }
  
      // For other values, perform standard comparison
      if (aValue < bValue) {
        return sortConfig.direction === 'asc' ? -1 : 1;
      }
      if (aValue > bValue) {
        return sortConfig.direction === 'asc' ? 1 : -1;
      }
      return 0; // equal values
    });
  }, [Tabledata, sortConfig]);
  const [isAccordionOpen, setAccordionOpen] = useState(false);
  const [expandedRows, setExpandedRows] = useState({});
  const toggleAccordion = (displayName) => {
    setExpandedRows((prevState) => ({
      ...prevState,
      [displayName]: !prevState[displayName], // Toggle the current row
    }));
  };
  const splitObject = (obj, part) => {
    if (!obj) return {};
  
    const keys = Object.keys(obj);
    const middleIndex = Math.ceil(keys.length / 2);
  
    const start = part === 0 ? 0 : middleIndex;
    const end = part === 0 ? middleIndex : keys.length;
  
    return keys.slice(start, end).reduce((result, key) => {
      result[key] = obj[key];
      return result;
    }, {});
  };
  return (
    <div style={{ marginTop: "2%" }}>
      <div style={{ display: "flex" }}>
        <div className="Button-ticket">
          <Stack direction="column" spacing={2}>
            <Button
              elevation={22}
              variant="contained"
              style={{
                textTransform: "capitalize",
                width: "13vw",
                height: "6vh",
                color: activeSection === "Equipment" ? "white" : "black",
                background: backgroundColor,
              }}
              onClick={() => handleSectionClick("Equipment")}
            >
              Equipment
            </Button>
            <Button
              variant="contained"
              elevation={22}
              style={{
                textTransform: "capitalize",
                width: "13vw",
                height: "6vh",
                color: activeSection === "Alarms" ? "white" : "black",
                background: backgroundColorr,
              }}
              onClick={() => handleSectionClick("Alarms")}
            >
              Alarms
            </Button>
          </Stack>
        </div>

        <div style={{ marginLeft: "2%", width: "100%" }}>
          <Stack direction="column" spacing={2}>
            <Card
              elevation={1}
              style={{ height: "7vh", width: "100%"}}
            >
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  {activeSection === "Alarms" ? (
                    <div>
                      <Tabs
                        value={value}
                        onChange={handleChange}
                        style={{padding:"2%"}}
                        sx={{ minHeight: "32px" }}
                        TabIndicatorProps={{ style: { height: "4px" } }}
                      >
                        <Tab
                      
                          label={
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <Typography
                                style={{
                                  fontSize: "14px",
                                  textTransform: "none",
                                  color: value === 0 ? "black" : "",
                                }}
                              >
                                Equipment
                              </Typography>
                              <div style={badge}>{equimentalarms.length}</div>
                            </div>
                          }
                          sx={{ minHeight: "32px" }}
                        />
                        <Tab
                          label={
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <Typography
                                style={{
                                  fontSize: "14px",
                                  textTransform: "none",
                                  color: value === 1 ? "black" : "",
                                }}
                              >
                                UserDefined
                              </Typography>
                              <div style={badge}>{siteAlarms.length}</div>
                            </div>
                          }
                          sx={{ minHeight: "32px" }}
                        />
                      </Tabs>
                    </div>
                  ) : (
                    <div style={{ marginLeft: "2%", marginTop:"1%"}}>
                      <Autocomplete
                        name="status"
                        disablePortal
                        id="combo-box-demo"
                        options={["Inverter", "Energy Meter", "SCB", "Sensor"]}
                        value={categoryvalue}
                        onChange={(event, newValue) => {
                          handleEquipment(newValue);
                        }}
                        size="small"
                        sx={{ width: "14vw" }}
                        renderInput={(params) => (
                          <TextField {...params} placeholder=" Jane" />
                        )}
                      />
                    </div>
                  )}
                </Grid>
              </Grid>
            </Card>

            <Card
              elevation={1}
              style={{
                height: "38vh", // Height set to 38% of the viewport height
                width: "100%", // Full width of the parent container
              // Hide horizontal scrollbar
              }}
            >
              {lodervalue ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop:"5%"
                  }}
                >
                  <FadeLoader color="#000440" loading={true} />
                </div>
              ) : (
                <div className="table-container-wrapper">
    <TableContainer style={{ maxHeight: '400px' }}>
      <Table aria-label="a dense table">
        <TableRow>
          {currentConfig?.headers?.length > 0 ? (
            currentConfig.headers.map((header, index) => (
              <TableCell key={index} align="center" style={{ color: "rgb(113, 118, 111)", position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 1 }}>
                <TableSortLabel
                  active={sortConfig.key === currentConfig.keys[index]}
                  direction={sortConfig.key === currentConfig.keys[index] ? sortConfig.direction : 'asc'}
                  onClick={() => handleSort(currentConfig.keys[index])}
                >
                  {header}
                </TableSortLabel>
              </TableCell>
            ))
          ) : (
            <div style={{ textAlign: "center", marginTop: "16vh" }}>
              {currentConfig?.message || 'No data available.'}
            </div>
          )}
        </TableRow>

        <TableBody>
        {sortedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, rowIndex) => (
  <React.Fragment key={rowIndex}>
    {/* Main Row */}
    <TableRow>
      {categoryvalue === "SCB" && (
        <TableCell align="center">
          <span
            style={{
              display: "flex",
              alignItems: "center",
            }}
            onClick={() => toggleAccordion(row.displayName)}
            aria-expanded={!!expandedRows[row.displayName]} // Check if the row is expanded
          >
            {expandedRows[row.displayName] ? (
              <KeyboardArrowUpOutlinedIcon
                style={{
                  padding: "0",
                  marginRight: "2px",
                }}
              />
            ) : (
              <KeyboardArrowDownOutlinedIcon
                style={{
                  padding: "0",
                  marginRight: "2px",
                  color: "darkgrey",
                }}
              />
            )}
            {/* Add equipment value next to the icon */}
            {` ${row.displayName}`}
          </span>
        </TableCell>
      )}

      {/* Add the remaining columns */}
      {currentConfig?.keys?.map((key, keyIndex) => (
        <TableCell key={keyIndex} align="center">
          {formatCellValue(key, row[key])}
        </TableCell>
      ))}
    </TableRow>

    {/* Expanded Details Row */}
    {expandedRows[row.displayName] && (
      <TableRow>
        <TableCell colSpan={currentConfig?.keys?.length + 1} align="center">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              gap: "16px",
              padding: "10px",
              backgroundColor: "#f5f5f5",
            }}
          >
            {/* Card */}
            <Scbaccorian row={row} currentConfig={currentConfig} />
          </div>
        </TableCell>
      </TableRow>
    )}
  </React.Fragment>
))}



    </TableBody>
      </Table>
    </TableContainer>



                </div>
              )}
            </Card>
            {lodervalue ? (
              <div></div>
            ) : (
              <div style={{marginTop:"1%"}}>
                {Tabledata.length > 0 && (
                  <Card style={{ height: "4vh", width: "100%" }}>
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-between"
                    >
                      <Grid item xs={2}></Grid>
                      <Grid
                        item
                        xs={10}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-end",
                        }}
                      >
                        <Stack spacing={2} direction="row">
                          <Pagination
                            count={Math.ceil(Tabledata.length / rowsPerPage)}
                            shape="rounded"
                            color="primary"
                            page={page + 1}
                            onChange={handleChangePage}
                            renderItem={(item) => (
                              <PaginationItem
                                slots={{
                                  previous: renderCustomPrevious,
                                  next: renderCustomNext,
                                }}
                                {...item}
                              />
                            )}
                          />
                          <Divider
                            orientation="vertical"
                            flexItem
                            style={{
                              color: "#E6E7E9",
                              height: "30px",
                              marginRight: "16px",
                            }}
                          />
                        </Stack>
                        <Stack
                          direction="row"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            marginLeft: "1%",
                          }}
                        >
                          <Typography
                            style={{
                              fontSize: "12px",
                              lineHeight: "14.52px",
                              fontWeight: "400",
                            }}
                          >
                            Go to page
                          </Typography>
                          <TextField
                            variant="standard"
                            style={{
                              width: "24px",
                              marginLeft: "5px",
                            }}
                            value={pageValue !== 0 ? pageValue : ""}
                            onChange={(e) => PageChange(e.target.value)}
                          />
                          <Button
                            style={{
                              padding: "0",
                              width: "12px",
                              fontSize: "14px",
                            }}
                            onClick={handleClickGo}
                            endIcon={
                              <ArrowForwardIcon style={{ fontSize: "16px" }} />
                            }
                          >
                            GO
                          </Button>
                          <Autocomplete
                            options={rowsPerPageOptions}
                            getOptionLabel={(option) => option.toString()}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="standard"
                                sx={{
                                  fontFamily: "Inter, sans-serif",
                                  width: 40,
                                }}
                              />
                            )}
                            value={rowsPerPage}
                            onChange={handleChangeRowsPerPage}
                            disableClearable
                            style={{ width: 120 }}
                          />
                        </Stack>
                      </Grid>
                    </Grid>
                  </Card>
                )}
              </div>
            )}

            <div></div>
          </Stack>
        </div>
      </div>
    </div>
  );
}
